<template>
  <!-- sm Modal start-->
  <div class="modal fade" :class="isOpen" id="modal-01" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true" style="z-index:9999">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">{{ $t('qa.complete') }}</div>
        <div class="modal-body" v-html="bodyText">
        </div>
        <div class="modal-footer">
          <a href="" class="button-modal">{{ $t('common.confirm') }}</a>		
        </div>
      </div>
    </div>
  </div>						
</template>

<script>
import '@/assets/bs3/js/bootstrap.js'
import '@/assets/bs3/css/bootstrap.css'
import '@/assets/css/common.css'

export default {
  name: 'alert',
  props: [
    'name'
  ],
  data: () => ({
    isOpen: false
  }),
  computed: {
    bodyText () {
      return this.name + this.$t('qa.body')
    },
  }
}
</script>