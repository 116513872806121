<template>
  <!-- container -->
  <div id="container">
    <!-- 본문 -->
    <div id="contents">        
      <div class="contactUs">
        <!-- 서브 배너 -->        
        <section class="subTopArea inner-fixed">
          <p v-html="$t('views.business.banner')"></p>
        </section>
        <!-- //서브 배너 -->

        <section class="contactUsWrap">
          <section class="contactUsBox f0 inner-fixed">
            <h2 class="fl">{{ $t('views.business.qa') }}</h2>
            <div class="fr contactUsWrite">
              <ul>
                <li><strong class="active" v-html="$t('views.business.name')"/></li>
                <li>
                  <input :class="nameError ? 'danger' : ''" :placeholder="$t('views.business.namep')" v-model="form.name">
                  <span v-if="nameError" class="textDanger">{{nameError}}</span>
                </li>
              </ul>
              <ul>
                <li><strong class="active">{{ $t('views.business.email') }}</strong></li>
                <li>
                  <input :class="emailError ? 'danger' : ''" :placeholder="$t('views.business.emailp')" v-model="form.email">
                  <span v-if="emailError" class="textDanger">{{emailError}}</span>
                </li>
              </ul>
              <ul>
                <li><strong>{{ $t('views.business.company') }}</strong></li>
                <li><input class="" :placeholder="$t('views.business.companyp')" v-model="form.company"></li>
              </ul>
              <ul>
                <li><strong>{{ $t('views.business.content') }}</strong></li>
                <li>
                  <textarea :placeholder="$t('views.business.contentp')" v-model="form.content" maxlength="1000"></textarea>
                  <span class="textInfo">{{form.content.length}}/1000</span>		
                </li>
              </ul>
            </div>
          </section>

          <section class="contactUsBox f0 inner-fixed">
            <h2 class="fl"><p v-html="$t('views.business.security1')"></p></h2>
            <div class="fr">
              <div class="contactUsInfo"><p v-html="$t('views.business.security2')"></p></div>
              <div class="check-wrap point" :class="checkedError ? 'danger': ''">
                <input type="checkbox" id="1" name="1" v-model="checked">
                <label for="1"><span class="checkbox"></span> <span class="title-check point">{{ $t('views.business.contents06') }} </span></label>
              </div>
            </div>
          </section>
          <div class="btn-cen inner-fixed"><a class="button-go-primary" @click="postBusiness">{{ $t('views.business.question') }}</a></div>

          <section class="map inner-fixed">
            <iframe 
              :src="`https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3164.582293870424!2d127.03918465110421!3d37.51776883457767!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x357ca4750b35278b%3A0x93079dbb7d9c9b0b!2zKOyjvCnslYTtgazrprQ!5e0!3m2!1s${$_gGet_Locale()}!2skr!4v1595644731657!5m2!1s${$_gGet_Locale()}!2skr`" 
              width="100%" 
              height="600" 
              frameborder="0" 
              style="border:0;" 
              allowfullscreen="" 
              aria-hidden="false" 
              icon="@/assets/images/image-main-banner-m1@3x.png"
              tabindex="0">
            </iframe>
            <p>{{ $t('views.business.address') }}</p>
          </section>

          <section class="contactUsBox f0 inner-fixed">
            <h3 class="fl">{{ $t('views.business.etcqa') }}</h3>
            <div class="fr contactUsWay">
              <ul>
                <li>
                  <strong>{{ $t('views.business.techqa') }}</strong>
                  <small><i class="icon-mail"></i> <a href="mailto:support@acryl.ai">support@acryl.ai</a></small>
                </li>
                <li>
                  <strong>{{ $t('views.business.generalqa') }}</strong>
                  <small><i class="icon-mail"></i> <a href="mailto:info@acryl.ai">info@acryl.ai</a></small>
                </li>
                <li>
                  <strong>{{ $t('views.business.tel') }}</strong>
                  <small><i class="icon-phone"></i> <a href="tel:02.557.4958">02.557.4958</a></small>
                </li>
                <li>
                  <strong>{{ $t('views.business.fax') }}</strong>
                  <small><i class="icon-fax"></i> 02.558.4958</small>
                </li>
              </ul>
            </div>
          </section>
        </section>
      </div>
      <Alert :name="form.name"></Alert>
    </div>
    <!-- 본문 -->
  </div>
  <!-- //container -->
</template>

<script>
import Alert from '@/components/Modal/AlertModal.vue'
import instance from '@/assets/js/api.js'
import $ from 'jquery'

export default {
  data() {
    return {
      form: {
        name: '',
        email: '',
        company: '',
        content: '',
      },
      nameError: '',
      emailError: '',
      checkedError: false,
      checked: false
    }
  },
  name: 'business',
  methods: {
    postBusiness () {
      const url = '/business'
      const method = 'post'
      const {
        form,
        checked
      } = this

      if (!form.name) this.nameError = this.$t('common.placeholder.nameerror') //'이메일 주소를 알려주시면 번개처럼 연락드려요!'
      else this.nameError = ''

      if (!form.email) this.emailError = this.$t('common.placeholder.emailerror') //'이메일 주소를 알려주시면 번개처럼 연락드려요!'
      else if (!/^[a-zA-Z-0-9_.+-]+@[A-z]+(\.)+[A-z]+$/.test(form.email)) this.emailError = this.$t('common.placeholder.emailerror2') //'올바른 이메일 주소를 입력해주세요!'
      else this.emailError = ''

      this.checkedError = !this.checked

      if (this.nameError || this.emailError || this.checkedError) {
        return
      }

      let data = new FormData()
      data.append('name', form.name)
      data.append('email', form.email)
      data.append('company', form.company)
      data.append('content', form.content)


      $('#modal-01').modal()
      let result = instance.post(url, data)
    },
  },
  components: {
    Alert
  },
}
</script>